import React from 'react';
import { FormattedMessage } from 'react-intl';
import { index } from '../util/array';

const channelOptions = [
  { label: <FormattedMessage id="app.mainline" />, value: 'mainline' },
  { label: <FormattedMessage id="app.outlet" />, value: 'outlet' },
];
const channel = {
  name: 'channel',
  label: 'app.channel',
  options: channelOptions,
  byValue: index(channelOptions, 'value'),
};

const businessModelOptions = [
  { label: <FormattedMessage id="app.concession" />, value: 'concession' },
  {
    label: <FormattedMessage id="app.company_operated" />,
    value: 'company_operated',
  },
  {
    label: <FormattedMessage id="app.commissionaire" />,
    value: 'commissionaire',
  },
  { label: <FormattedMessage id="app.franchise" />, value: 'franchise' },
];

const businessModel = {
  name: 'businessModel',
  label: 'app.business_model',
  options: businessModelOptions,
  byValue: index(businessModelOptions, 'value'),
};

export const params = { channel, businessModel };

export const allParams = [channel, businessModel];
