export const YSeriesConfig = {
  TRAFFIC: {},
  NET_SELL_OUT_VALUE: {},
  CONVERSION_PERCENTAGE: {
    format: val => val * 100,
    unit: '%',
  },
};

export const TrafficConversionChartDetails = {
  conversion: {
    datakey: 'CONVERSION_PERCENTAGE',
    color: '#f7a35c',
    name: 'Conversion',
  },
  traffic: {
    datakey: 'TRAFFIC',
    color: '#FFFFFF',
    name: 'Traffic',
  },
};

export const HourlySalesChartDetails = {
  trends_ly: {
    datakey: 'trends_ly',
    color: '#FFFFFF',
    name: 'Last Year',
  },
  trends: {
    datakey: 'trends',
    color: '#f7a35c',
    name: 'This Year',
  },
};
