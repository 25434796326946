import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { CardGrid, PromotedMetricCard, AddKPICard } from '../components/Cards';
import Header from '../components/Header';
import KPIList from '../components/KPIList';
import { Panel } from '../components/Panel';
import LoadingBar from '../components/LoadingBar';
import SectionSpacer from '../components/SectionSpacer';
import TimeStampPanel from '../components/TimeStampPanel';
import MetricsShell from '../data/components/MetricsShell';
import SortShell from '../data/components/SortShell';
import useMetricsData from '../data/hooks/useMetricsData';
import {
  convertTextInTranslationKey,
  transformMetricsInPanels,
} from '../data/utils/transforms';
import { LocationAndParamsShape } from '../location';
import { GTAG_EVENT, PageView } from '../gtag';
import { ErrorPage } from '../components/Errors';

const FavMetricCard = ({ onClick, metric, useLLY }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick();
  };
  if (!metric) {
    return <AddKPICard onClick={handleClick} />;
  }
  return (
    <PromotedMetricCard
      onClick={handleClick}
      key={metric.id}
      metric={metric}
      useLLY={useLLY}
      moreDetails={metric.hasTrends}
    />
  );
};

const SentimentCards = ({
  data,
  favMetric,
  onFavMetricClick,
  useLLY,
  useUsdAmount,
}) => {
  return (
    data && (
      <>
        <CardGrid itemXs={12} justify="flex-start">
          {data.map(m => (
            <PromotedMetricCard
              key={m.id}
              metric={m}
              useLLY={useLLY}
              moreDetails={m.hasTrends}
              useUsdAmount={useUsdAmount}
            />
          ))}
          <FavMetricCard
            metric={favMetric}
            onClick={onFavMetricClick}
            useLLY={useLLY}
          />
        </CardGrid>
      </>
    )
  );
};

const KPIsCore = ({
  promotedKpis,
  favMetric,
  onSelectSort,
  onSelectFav,
  otherKpis,
  useLLY,
  useUsdAmount,
}) => {
  const { formatMessage: f } = useIntl();
  const transformedOtherKPIs = transformMetricsInPanels(otherKpis);
  return (
    <>
      <Header
        title={f({ id: 'app.main_kpis' })}
        icon
        onIconClick={onSelectFav}
      />
      <SentimentCards
        data={promotedKpis}
        favMetric={favMetric}
        onFavMetricClick={onSelectFav}
        useLLY={useLLY}
        useUsdAmount={useUsdAmount}
      />
      <SectionSpacer />
      <Header
        title={f({ id: 'app.other_kpis' })}
        icon
        onIconClick={onSelectSort}
      />
      {transformedOtherKPIs.map(panel => {
        const metricsToShow = panel.sections.filter(item => item.show);

        return metricsToShow.length > 0 ? (
          <Panel
            title={f({ id: convertTextInTranslationKey(panel.panelName) })}
            key={panel.panelName}
            disabled
          >
            <KPIList
              metrics={metricsToShow}
              key={`${panel.panelName}-section`}
              useLLY={useLLY}
              useUsdAmount={useUsdAmount}
            />
          </Panel>
        ) : null;
      })}
    </>
  );
};

const KPIsDataContainer = ({
  period,
  location: locationAndParams,
  userSettings,
  showTimeStamp,
}) => {
  const { useUsdAmount, includeTaxes, useLLY } = userSettings;
  const { location, params: locationParams } = locationAndParams;
  const salesBucketValue = 'total_sales';
  const { formatMessage: f } = useIntl();
  const locationId = location.id;
  const { responseData = [], loading, error, refetch } = useMetricsData(
    locationId,
    locationParams,
    period.id,
    useUsdAmount,
    includeTaxes,
    useLLY,
    period.periods_trends
  );

  if (loading) {
    return <LoadingBar />;
  }
  let data = responseData[salesBucketValue] || [];
  if (error) {
    return (
      <ErrorPage
        message={f({ id: 'app.could_not_load_kpis_data' })}
        onClickRetry={refetch}
      />
    );
  }

  return (
    <>
      {data.formattedTimeStamp && showTimeStamp && (
        <TimeStampPanel formattedValue={data.formattedTimeStamp} />
      )}
      <SortShell
        onSort={({ id }) => {
          GTAG_EVENT({ view: 'sort', category: 'KPIs', label: id });
        }}
      >
        {(sort, toggleSortDrawer) => {
          const promotedKpis = data.filter(x => x.promoted);
          const otherKpis = data.filter(x => !x.promoted).sort(sort.func);
          return (
            <MetricsShell metrics={otherKpis}>
              {(favMetric, toggleFavSelect) => (
                <PageView
                  params={{
                    period_id: period.id,
                    location: location.name,
                    channel: locationParams.channel,
                  }}
                >
                  {loading ? (
                    <LoadingBar />
                  ) : (
                    <>
                      <KPIsCore
                        promotedKpis={promotedKpis}
                        favMetric={favMetric}
                        otherKpis={otherKpis.filter(x => x !== favMetric)}
                        onSelectSort={toggleSortDrawer}
                        onSelectFav={toggleFavSelect}
                        useLLY={useLLY}
                        loading={loading}
                        useUsdAmount={useUsdAmount}
                      />
                    </>
                  )}
                </PageView>
              )}
            </MetricsShell>
          );
        }}
      </SortShell>
    </>
  );
};

KPIsDataContainer.propTypes = {
  period: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  location: LocationAndParamsShape.isRequired,
  userSettings: PropTypes.shape({
    useUsdAmount: PropTypes.bool.isRequired,
    includeTaxes: PropTypes.bool.isRequired,
  }),
};

const KPIs = React.memo(
  KPIsDataContainer,
  (prevProps, nextProps) =>
    prevProps.location === nextProps.location &&
    prevProps.period === nextProps.period &&
    prevProps.userSettings === nextProps.userSettings
);

export default KPIs;
