import React from 'react';
import { params } from './data';
import { FormattedMessage } from 'react-intl';

function formatChannel(value) {
  const formatted = params.channel.byValue.get(value)?.label;
  if (!formatted) {
    throw new Error(
      `Unable to format channel. Unknown channel value: ${JSON.stringify(
        value
      )}`
    );
  }
  return value;
}

function formatBusinessModel(value) {
  const formatted = params.businessModel.byValue.get(value)?.label;
  if (!formatted) {
    throw new Error(
      `Unable to format business model. Unknown business model value: ${JSON.stringify(
        value
      )}`
    );
  }
  return value;
}

export function formatParams(params) {
  const { channel, businessModel } = params || {};
  let formattedParts = [];
  if (channel) {
    formattedParts.push(formatChannel(channel));
  }
  if (businessModel) {
    formattedParts.push(formatBusinessModel(businessModel));
  }
  let paramStr = <FormattedMessage id="app.all_stores" />;
  if (formattedParts.length > 0) {
    if (formattedParts.length === 1) {
      paramStr = (
        <div>
          <FormattedMessage id={`app.${formattedParts[0]}`} />{' '}
          <FormattedMessage id={'app.only'} />
        </div>
      );
    } else {
      paramStr = (
        <div>
          <FormattedMessage id={`app.${formattedParts[0]}`} /> /{' '}
          <FormattedMessage id={`app.${formattedParts[1]}`} />
        </div>
      );
    }
  }
  return paramStr;
}

export function formatLocationAndParams(locationAndParams) {
  const isStore = locationAndParams?.location?.level === 'store';

  return {
    location: locationAndParams?.location?.name,
    params: isStore ? undefined : formatParams(locationAndParams?.params),
  };
}
