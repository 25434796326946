import { transformMetrics_v2 } from './transforms';
import { chooseCurrencyFromMetricsResponses } from './currencySelectors';
import { pairMetrics } from './pairMetrics';

export function transformMetricsPair(
  kpisResponse,
  responseA,
  responseB,
  useUsdAmount,
  includeTaxes,
  periodId,
  useLLY,
  trendsPeriods
) {
  let finalFormattedData = {};
  const responseAMetrics = responseA.metrics;
  const responseBMetrics = responseB.metrics;

  Object.keys(responseAMetrics).forEach(salesType => {
    const currency = chooseCurrencyFromMetricsResponses(
      { metrics: responseAMetrics[salesType] },
      { metrics: responseBMetrics[salesType] },
      useUsdAmount
    );
    const dataA = transformMetrics_v2({
      metricsResponse: { metrics: responseAMetrics[salesType] },
      kpiMetaResponse: kpisResponse,
      currency,
      includeTaxes,
      useLLY,
      periodId,
      trendsPeriods,
    });
    const dataB = transformMetrics_v2({
      metricsResponse: { metrics: responseBMetrics[salesType] },
      kpiMetaResponse: kpisResponse,
      currency,
      includeTaxes,
      useLLY,
      periodId,
      trendsPeriods,
    });
    const paired = pairMetrics(dataA ?? [], dataB ?? []);
    paired.formattedTimeStamp =
      dataA.formattedTimeStamp ?? dataB.formattedTimeStamp;

    finalFormattedData[salesType] = paired;
  });

  return finalFormattedData;
}
