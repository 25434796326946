import { PublicClientApplication } from '@azure/msal-browser';

const redirectUri = window.location.origin + process.env.PUBLIC_URL;

export const msalConfigScope =
  'api://e790df93-1eae-47ea-aba3-040cea37d435/.default';

export const init = async config => {
  const { AZURE_ISSUER: issuer, AZURE_CLIENT_ID: clientId } = config;

  if (!issuer) {
    return { authRequired: false };
  }

  const msalConfig = {
    auth: {
      clientId: clientId,
      authority: issuer,
      redirectUri: redirectUri,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  };
  let error;
  let instance = new PublicClientApplication(msalConfig);
  let tokenResponse;
  let user;

  const updateErrors = caughtError => {
    error = caughtError;
  };

  try {
    await instance.initialize();
    tokenResponse = await instance.handleRedirectPromise();

    let accountObj;
    if (tokenResponse) {
      accountObj = tokenResponse.account;
    } else {
      accountObj = instance.getAllAccounts()[0];
    }

    if (accountObj && tokenResponse) {
      console.log('[AuthService.init] Got valid accountObj and tokenResponse');
    } else if (accountObj) {
      console.log('[AuthService.init] User has logged in, but no tokens.');
      try {
        tokenResponse = await instance.acquireTokenSilent({
          account: instance.getAllAccounts()[0],
          scopes: [msalConfigScope],
        });
        user = instance.getAllAccounts();
      } catch (err) {
        await instance.acquireTokenRedirect({
          scopes: [msalConfigScope],
        });
      }
    } else {
      console.log(
        '[AuthService.init] No accountObject or tokenResponse present. User must now login.'
      );
    }
  } catch (error) {
    console.error(
      '[AuthService.init] Failed to handleRedirectPromise()',
      error
    );
    updateErrors('Failed to handleRedirectPromise()');
  }
  return {
    authRequired: true,
    error,
    user,
    instance,
  };
};
