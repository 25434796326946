import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { ThemeProvider } from '@material-ui/core';
import ErrorBoundary from './ErrorBoundary';
import * as gtag from './gtag';
import * as sentry from './sentry';
import theme from './theme';
import {
  createUpdateManager,
  register as registerServiceWorker,
  UpdateManager,
} from './serviceWorker';
import { reportError } from './logging';
import LanguageProvider from './language';
import { translationMessages } from './language/i18n';
import { init as initSecurity, SecurityProvider } from './auth';
import { ConfigError, ConfigProvider, loadConfig } from './config';

import App from './components/App';
import { ErrorPage } from './components/Errors';
import { PreferencesProvider } from './data/user-preferences';
import { ms } from './util/timers';

import './index.css';
import { RetryPeriodProvider } from './data/hooks/useRetryPeriod';

const updateManager = createUpdateManager({
  checkIntervalMs: ms({ minutes: 5 }),
});

const handleClickRetry = () => {
  // Sending the user to PUBLIC_URL will get rid of any extra parts to the
  // URL that may be causing a problem. For example '#state=invalid&...'
  // hash parameters returned from the OIDC issuer.
  window.location.replace(process.env.PUBLIC_URL + '/');
};

const main = async messages => {
  const config = await loadConfig();
  const gtmId = config.GTM_CONTAINER_ID;
  sentry.init(config);
  gtag.init(config);
  const gtmArgs = {
    gtmId: gtmId,
  };

  // GTM initialization
  TagManager.initialize(gtmArgs);

  const { authRequired, user, instance, error } = await initSecurity(config);

  ReactDOM.render(
    <ErrorBoundary onClickRetry={handleClickRetry}>
      <ConfigProvider config={config}>
        <UpdateManager.Provider value={updateManager}>
          <SecurityProvider
            authRequired={authRequired}
            initialUser={user}
            instance={instance}
            error={error}
          >
            <LanguageProvider messages={messages}>
              <PreferencesProvider>
                <ThemeProvider theme={theme}>
                  <RetryPeriodProvider>
                    <App />
                  </RetryPeriodProvider>
                </ThemeProvider>
              </PreferencesProvider>
            </LanguageProvider>
          </SecurityProvider>
        </UpdateManager.Provider>
      </ConfigProvider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

const catchError = error => {
  reportError(error);

  let diagnosticInfo;
  if (error instanceof ConfigError) {
    // A config error will have occurred before Sentry got initialized, so there
    // will be no error report. Display extra info to help resolve issues.
    diagnosticInfo =
      error.config === undefined
        ? 'app.missing_configuration'
        : 'app.invalid_configuration';
  } else if (!sentry.isEnabled()) {
    diagnosticInfo = 'app.problem_could_not_be_reported';
  }

  ReactDOM.render(
    <IntlProvider locale={'en'} key={'en'} messages={translationMessages['en']}>
      <ThemeProvider theme={theme}>
        <ErrorPage
          title={<FormattedMessage id="app.unable_to_load" />}
          message={<FormattedMessage id={diagnosticInfo} />}
          onClickRetry={handleClickRetry}
          fullPageHeight
          version={process.env.REACT_APP_VERSION}
        />
      </ThemeProvider>
    </IntlProvider>,
    document.getElementById('root')
  );
};

const browserError = async messages => {
  document.getElementById('root').style.display = 'none';

  ReactDOM.render(
    <IntlProvider locale={'en'} key={'en'} messages={messages['en']}>
      <div className="errorRoot">
        <div class="errorHeader">
          <FormattedMessage id="app.unable_to_load" />
        </div>
        <p class="errorText">
          <FormattedMessage id="app.browser_error_message" />
        </p>
        <button class="retryButton" onClick={() => window.location.reload()}>
          <FormattedMessage id="app.retry" />
        </button>
      </div>
    </IntlProvider>,
    document.getElementById('errorRoot')
  );
};

// check browser
function checkBrowser() {
  try {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    if (isIE || isFirefox) {
      throw new Error('Browser Error');
    }
  } catch (error) {
    if (error.message === 'Browser Error') {
      browserError(translationMessages);
    }
  }
}

if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/es.js'),
      ])
    )
    .then(() =>
      main(translationMessages).catch(error => {
        catchError(error);
      })
    );
} else {
  main(translationMessages).catch(error => {
    catchError(error);
  });
}
registerServiceWorker();

checkBrowser();
