import { withStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { Card } from '../../components/Cards';
import { CollapseButtonIcon } from '../../components/Icons';
import LoadingBar from '../../components/LoadingBar';
import { TreeSelect, SelectDrawer, IconPlacer } from '../../components/Select';
import Transition from '../../components/Select/Transition';
import useLocationData from '../hooks/useLocationData';
import { useRetryPeriod } from '../hooks/useRetryPeriod';
import { LocationParamsSelect, formatParams } from '../../location';
import { Text2 } from '../../typography';
import { usePreferencesManager } from '../user-preferences';
import { noop } from '../../util/function';
import * as tree from '../../util/tree';

import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

/**
 * Generates a tree of options for locations.
 *
 * Adds channel options as the first children of all branch nodes.
 */
const createLocationSelectOptions = node => {
  const option = {
    label: node.name,
    // `hasData` must be strictly `false` to be unselectable, other falsy values
    // (e.g. `undefined`) are treated as having data.
    isUnselectable: node.hasData === false,
    location: node,
    channel: undefined,
  };

  if (tree.isBranch(node)) {
    option.children = node.children
      .map(child => {
        const childOption = createLocationSelectOptions(child);
        childOption.parent = option;
        return childOption;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  return option;
};

const LocationParamsButton = withStyles(
  theme => ({
    root: {
      alignItems: 'stretch',
      borderRadius: 6,
    },
    actionArea: {
      borderRadius: 6,
    },
    wrapper: {
      margin: theme.spacing(1.5, 1.5, 0),
    },
    content: {
      padding: theme.spacing(1.5),
      textAlign: 'left',
    },
  }),
  { name: 'LriLocationParamsButton' }
)(({ children, classes, ...props }) => (
  <div className={classes.wrapper}>
    <Card
      classes={{ root: classes.root, actionArea: classes.actionArea }}
      {...props}
    >
      <div className={classes.content}>
        <IconPlacer
          align="left"
          iconRightSpacing={0}
          icon={
            <CollapseButtonIcon
              style={{
                color: 'rgba(0, 0, 0, 0.3)',
                width: 12,
                transform: 'rotate(-90deg)',
              }}
            />
          }
        >
          <Text2 bold>{children}</Text2>
        </IconPlacer>
      </div>
    </Card>
  </div>
));

const LocationsShell = ({ children, onClose = noop }) => {
  const pm = usePreferencesManager();
  const { formatMessage: f } = useIntl();

  const { data, locations: LocationsData, loading, error } = useLocationData();
  const rootOption = useMemo(() => {
    if (data) {
      return createLocationSelectOptions(data);
    }
  }, [data]);
  const getOptionById = useCallback(
    id =>
      rootOption && tree.find(rootOption, option => option.location.id === id),
    [rootOption]
  );
  const getOptionByKey = useCallback(key => getOptionById(pm.get(key)), [
    pm,
    getOptionById,
  ]);
  const getParamsByKey = useCallback(
    key => ({
      channel: pm.get(`${key}_channel`),
      businessModel: pm.get(`${key}_businessModel`),
    }),
    [pm]
  );

  /**
   * @param {string} key
   * @return Either `undefined` or a valid `LocationAndParams` object.
   */
  const getLocationAndParamsByKey = useCallback(
    key => {
      const option = getOptionByKey(key);
      if (key === 'global' && !option) {
        return getLocationAndParamsByKey('home');
      } else if (key === 'storeA' && !option) {
        return getLocationAndParamsByKey('global');
      }
      return (
        option && {
          location: option.location,
          params: getParamsByKey(key),
        }
      );
    },
    [getOptionByKey, getParamsByKey]
  );

  // If the user does not have a home location set then we show them the drawer
  // in fullscreen mode and force them to pick one.
  const hasHomeLocation = Boolean(pm.get('home'));
  const [isDrawerOpen, setDrawerOpen] = useState(!hasHomeLocation);
  const fullScreen = !hasHomeLocation;
  // When immediateMode is true changes to the location selection are persisted
  // to local storage right away (without closing the drawer). When
  // immediateMode is false the selection is not persisted until the drawer is
  // closed.
  const immediateMode = hasHomeLocation;
  // The 'key' determines which location is currently being selected e.g. if the
  // drawer is opened with key=storeA then it changes the first column of the
  // compare page.
  const [key, setKey] = useState(hasHomeLocation ? 'global' : 'home');

  const [selectedOption, setSelectedOption] = useState();
  const [selectedParams, setSelectedParams] = useState();
  const [paramsOpen, setParamsOpen] = useState(undefined);
  useEffect(() => {
    if (rootOption) {
      const selectedLocationAndParams = getLocationAndParamsByKey(key);
      setSelectedOption(getOptionById(selectedLocationAndParams?.location?.id));
      setSelectedParams(selectedLocationAndParams?.params);
    }
  }, [rootOption, key, getLocationAndParamsByKey, getOptionById]);

  const setSelectedParam = (name, value) =>
    setSelectedParams(prevParams => ({ ...prevParams, [name]: value }));

  const toggleSelect = passedKey => () => {
    setKey(passedKey);
    setParamsOpen(undefined);
    setDrawerOpen(true);
  };

  const setOrClearPref = (prefKey, value) =>
    value ? pm.set(prefKey, value) : pm.delete(prefKey);

  const clearParams = overrideKey => {
    const updateKey = overrideKey || key;
    setSelectedParams(undefined);
    setOrClearPref(`${updateKey}_channel`, undefined);
    setOrClearPref(`${updateKey}_businessModel`, undefined);
  };

  /**
   * @param {string} [overrideKey]
   *   Optionally override the key to which the current selections are saved. If
   *   left unspecified selections are saved using the key in component state.
   * @returns {boolean}
   *   True if a valid option was selected and was persisted to preferences,
   *   otherwise false.
   */
  const persistSelections = overrideKey => {
    if (selectedOption) {
      const updateKey = overrideKey || key;
      pm.set(`${updateKey}`, selectedOption.location.id);
      if (selectedOption.location.level === 'store') {
        clearParams();
      } else {
        setOrClearPref(`${updateKey}_channel`, selectedParams?.channel);
        setOrClearPref(
          `${updateKey}_businessModel`,
          selectedParams?.businessModel
        );
      }
    }
    return Boolean(selectedOption);
  };

  const { setRetryPeriod } = useRetryPeriod();

  const getDrawerProps = () => ({
    open: isDrawerOpen,
    fullScreen,
    showBack: Boolean(paramsOpen),
    onBack: () => {
      setParamsOpen(false);
    },
    onClose: () => {
      let saved = true;
      if (!immediateMode) {
        saved = persistSelections();
        if (key === 'home') {
          persistSelections('global');
        }
      } else if (selectedOption?.location.level === 'store') {
        clearParams();
      }

      if (saved) {
        setDrawerOpen(false);
        setRetryPeriod(false);
        onClose();
      }
    },
    title:
      key === 'home' ? (
        <FormattedMessage id="app.select_your_default_location" />
      ) : (
        <FormattedMessage id="app.select_a_location" />
      ),
    classes: { title: key === 'home' ? 'select-location-title-class' : '' },
  });

  const handleParamsChange = (param, value) => {
    if (immediateMode && ['channel', 'businessModel'].includes(param)) {
      setOrClearPref(`${key}_${param}`, value);
    }
    setSelectedParam(param, value);
  };
  const handleTreeSelectChange = value => {
    if (immediateMode) {
      pm.set(key, value.location.id);
      setRetryPeriod(false);
      setDrawerOpen(false);
    }
    setSelectedOption(value);
  };
  const getSelected = value => {
    if (value) {
      pm.set(key, value);
      setSelectedOption(getOptionById(value));
      setDrawerOpen(false);
    }
  };

  return (
    <>
      {error && children(getLocationAndParamsByKey, () => null, error)}
      {loading && <LoadingBar />}

      {!error && rootOption && (
        <>
          {hasHomeLocation && children(getLocationAndParamsByKey, toggleSelect)}
          <SelectDrawer
            closeContent={
              <Text2 gray style={{ textTransform: 'none' }}>
                {f({ id: 'app.done' })}
              </Text2>
            }
            {...getDrawerProps()}
          >
            {paramsOpen ? (
              <Transition key="params" appear direction="right">
                <div>
                  <LocationParamsSelect
                    {...selectedParams}
                    onChange={handleParamsChange}
                  />
                </div>
              </Transition>
            ) : (
              <Transition
                key="treeSelect"
                appear={paramsOpen !== undefined}
                direction="left"
              >
                <div>
                  <LocationParamsButton onClick={() => setParamsOpen(true)}>
                    {formatParams(selectedParams)}
                  </LocationParamsButton>
                  <Autocomplete
                    style={{ margin: '12px 12px 0px' }}
                    id="store-search"
                    options={LocationsData}
                    getOptionLabel={option => option.name}
                    value={LocationsData.find(
                      option => option.id === selectedOption?.location?.id
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        getSelected(value.id);
                      }
                    }}
                    renderOption={option => {
                      return (
                        <React.Fragment>
                          <Radio
                            style={{ marginRight: 8 }}
                            checked={
                              option?.id === selectedOption?.location?.id
                            }
                            onChange={event => getSelected(event.target.value)}
                            value={option.id}
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={f({ id: 'app.search_your_store' })}
                          placeholder={f({ id: 'app.find_your_store' })}
                          onChange={params.inputProps.onChange}
                        />
                      );
                    }}
                  />
                  ;
                  <TreeSelect
                    openAt={selectedOption || rootOption}
                    selected={selectedOption}
                    onSelect={handleTreeSelectChange}
                  />
                </div>
              </Transition>
            )}
          </SelectDrawer>
        </>
      )}
    </>
  );
};

LocationsShell.propTypes = {
  onClose: PropTypes.func,
};

export default LocationsShell;
