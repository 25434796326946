import { Container, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { clearLocalStorageApiCache } from '../api/localstorage';
import { SecureRoute } from '../auth';
import LocationsShell from '../data/components/LocationsShell';
import PeriodsShell from '../data/components/PeriodsShell';
import UserShell from '../data/components/UserShell';
import { formatLocationAndParams } from '../location';
import ErrorBoundary from '../ErrorBoundary';
import {
  Comparison,
  Login,
  Today,
  KPIs,
  Products,
  KPIDefinition,
} from '../pages';
import { useRetryPeriod } from '../data/hooks/useRetryPeriod';
import { useUpdateManager } from '../serviceWorker';
import { AppBar } from './AppBar';
import BottomBar from './BottomBar';
import { ErrorPageOrChildren } from './Errors';
import { usePreferencesManager } from '../data/user-preferences';
import { getPeriods } from '../api';
import { useSecurity } from '../auth';
import { useConfig } from '../config';

const useStyles = props => {
  return makeStyles(
    theme => ({
      contentArea: {
        paddingLeft: `calc(${theme.spacing(
          1.5
        )}px + env(safe-area-inset-left))`,
        paddingRight: `calc(${theme.spacing(
          1.5
        )}px + env(safe-area-inset-right))`,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      app: {
        minHeight: '100vh',
        background: props.showPeriodsBar
          ? theme.palette.background.default
          : theme.palette.primary.main,
        color: theme.palette.text.primary,
      },
    }),
    { name: 'LriApp' }
  );
};
const RoutedApp = props => {
  useEffect(() => {
    clearLocalStorageApiCache();
  }, []);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <App {...props} />
    </Router>
  );
};

const checkPeriodsData = (baseUrl, fetchOptions, refreshedDate, history) => {
  const result = getPeriods(baseUrl, {}, fetchOptions)
    .then(responseData => {
      if (responseData?.current_date !== refreshedDate) {
        history.go(0);
      }
    })
    .catch(error => console.warn(error));
  return result;
};

const App = () => {
  const showTimeStamp = false; // using this flag to hide timestamp panel for temporary in all screens
  const { formatMessage: f } = useIntl();
  const history = useHistory();
  const location = useLocation();
  const pm = usePreferencesManager();
  const { setRetryPeriod } = useRetryPeriod();
  const { checkForUpdates } = useUpdateManager();
  const {
    API_BASE_URL: baseUrl,
    DATA_REFRESH_START_HOUR: refreshHourStart,
    DATA_REFRESH_END_HOUR: refreshHourEnd,
  } = useConfig();
  const { accessToken } = useSecurity();
  const fetchOptions = accessToken
    ? { headers: { Authorization: `Bearer ${accessToken}` } }
    : {};

  const handleBottomBarChange = (e, value) => {
    history.push(`/${value}`);
    setRetryPeriod(false);
    checkForUpdates();
  };
  // var d = new Date().toLocaleString('en-US', {
  //   timeZone: 'America/Los_Angeles',
  // });
  const handleClickRetry = () => {
    history.go(0);
  };

  setInterval(() => {
    var presentTime = new Date().getTime();
    const dataRefreshDate = JSON.parse(pm.get('data_refresh_date') || '{}');
    var refreshTime = dataRefreshDate?.period_updated;
    const refreshedDate = dataRefreshDate?.current_date;
    const timeDiff = (presentTime - refreshTime) / 60000;
    let getCurrentHour = new Date().getUTCHours();
    if (
      getCurrentHour >= refreshHourStart &&
      getCurrentHour <= refreshHourEnd &&
      timeDiff > 30
    ) {
      checkPeriodsData(baseUrl, fetchOptions, refreshedDate, history);
    }
  }, 900000); // 15 * 60 * 1000

  const showPeriodsBar = location.pathname !== '/';
  const styles = useStyles({ showPeriodsBar })();

  return (
    <div className={styles.app}>
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <SecureRoute path="/">
          <PeriodsShell onClose={checkForUpdates}>
            {(
              period, // selected fixedPeriodOptions
              togglePeriodSelect,
              getSelectedPeriod,
              getTodaysPeriod,
              periodsError
            ) => (
              <LocationsShell onClose={checkForUpdates}>
                {(
                  getLocationAndParams,
                  toggleLocationSelect,
                  locationError
                ) => {
                  const locationAndParams = getLocationAndParams('global');
                  let {
                    location: formattedLocation,
                    params: formattedParams,
                  } = formatLocationAndParams(locationAndParams);

                  return (
                    <UserShell onClose={checkForUpdates}>
                      {(settings, toggleSideMenu) => (
                        <>
                          <Switch>
                            <Route path="/compare">
                              <AppBar
                                mainTitle={f({ id: 'app.compare' })}
                                secondaryTitle={period}
                                onPeriodChangeClick={togglePeriodSelect}
                                onAvatarClick={toggleSideMenu}
                                period={getSelectedPeriod()}
                              />

                              <Container className={styles.contentArea}>
                                <ErrorPageOrChildren
                                  onClickRetry={handleClickRetry}
                                  errors={[locationError, periodsError]}
                                >
                                  <ErrorBoundary>
                                    <Comparison
                                      period={getSelectedPeriod()}
                                      locationA={getLocationAndParams('storeA')}
                                      locationAOnClick={toggleLocationSelect(
                                        'storeA'
                                      )}
                                      locationB={getLocationAndParams('storeB')}
                                      locationBOnClick={toggleLocationSelect(
                                        'storeB'
                                      )}
                                      userSettings={settings}
                                      showTimeStamp={showTimeStamp}
                                    />
                                  </ErrorBoundary>
                                </ErrorPageOrChildren>
                              </Container>
                            </Route>
                            <Route path="/kpi-definition">
                              <ErrorBoundary>
                                <AppBar
                                  mainTitle={f({ id: 'app.kpi-definition' })}
                                  secondaryTitle={period}
                                  onPeriodChangeClick={togglePeriodSelect}
                                  onAvatarClick={toggleSideMenu}
                                  period={getSelectedPeriod()}
                                  showPeriodsBar={false}
                                />
                                <KPIDefinition />
                              </ErrorBoundary>
                            </Route>
                            <Route path="/">
                              <AppBar
                                toggleLocationSelect={toggleLocationSelect(
                                  'global'
                                )}
                                mainTitle={formattedLocation}
                                subtitle={formattedParams}
                                secondaryTitle={period}
                                onPeriodChangeClick={togglePeriodSelect}
                                onAvatarClick={toggleSideMenu}
                                showPeriodsBar={showPeriodsBar}
                                period={getSelectedPeriod()}
                              />
                              <Container className={styles.contentArea}>
                                <ErrorPageOrChildren
                                  onClickRetry={handleClickRetry}
                                  errors={[locationError, periodsError]}
                                >
                                  <Route path="/today">
                                    <Redirect to="/" />
                                  </Route>
                                  <Route exact path="/">
                                    <ErrorBoundary>
                                      <Today
                                        period={getTodaysPeriod()}
                                        location={getLocationAndParams(
                                          'global'
                                        )}
                                        userSettings={settings}
                                        showTimeStamp={showTimeStamp}
                                      />
                                    </ErrorBoundary>
                                  </Route>
                                  <Route exact path="/kpis">
                                    <ErrorBoundary>
                                      <KPIs
                                        period={getSelectedPeriod()}
                                        location={getLocationAndParams(
                                          'global'
                                        )}
                                        userSettings={settings}
                                        showTimeStamp={showTimeStamp}
                                      />
                                    </ErrorBoundary>
                                  </Route>
                                  <Route path="/products">
                                    <ErrorBoundary>
                                      <Products
                                        period={getSelectedPeriod()}
                                        location={getLocationAndParams(
                                          'global'
                                        )}
                                        userSettings={settings}
                                        showTimeStamp={showTimeStamp}
                                      />
                                    </ErrorBoundary>
                                  </Route>
                                </ErrorPageOrChildren>
                              </Container>
                            </Route>
                          </Switch>
                          <BottomBar
                            value={location.pathname.substr(1)}
                            onChange={handleBottomBarChange}
                          />
                        </>
                      )}
                    </UserShell>
                  );
                }}
              </LocationsShell>
            )}
          </PeriodsShell>
        </SecureRoute>
      </Switch>
    </div>
  );
};

export default RoutedApp;
