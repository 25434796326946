import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { length } from 'ramda';
import { YSeriesConfig, HourlySalesChartDetails } from './constants';

const styles = () => ({
  container: {
    margin: '0 auto',
    '& .recharts-legend-wrapper': {
      '& li.recharts-legend-item:last-child': {
        marginRight: 'auto !important',
      },
      '& .recharts-legend-item-text': {
        verticalAlign: 'middle',
      },
    },
  },
});

const getTrendValue = (trends, tick, yAxisKey) => {
  if (trends[tick] === undefined) {
    trends[tick] = { [yAxisKey]: 0 };
  }
  let yAxisKeyValue = trends[tick][yAxisKey];
  if (yAxisKeyValue === undefined) {
    yAxisKeyValue = 0;
  }
  return YSeriesConfig[yAxisKey].format
    ? YSeriesConfig[yAxisKey].format(yAxisKeyValue)
    : yAxisKeyValue;
};

const createSeries = (trends, trends_ly, xAxis, yAxisKey) => {
  const data = [];
  const ySeriesData = {};

  xAxis.forEach((tick, index) => {
    ySeriesData.trends = getTrendValue(trends, tick, yAxisKey);
    ySeriesData.trends_ly = getTrendValue(trends_ly, tick, yAxisKey);

    data.push({
      hours: tick,
      index,
      ...ySeriesData,
    });
  });

  return data;
};

const useStyles = makeStyles(
  () => ({
    customizeTooltip: {
      backgroundColor: '#080c94',
      opacity: 0.95,
      padding: 16,
      borderRadius: 10,
      borderColor: '#fff',
    },
    tooltipHeader: {
      color: '#fff',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      paddingBottom: 10,
      borderBottom: '1px solid #fff',
      marginBottom: 10,
    },
    payloadContent: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
  }),
  { name: 'CustomizedTooltip' }
);

const CustomizedTooltip = ({ label, payload, active, currencyCode }) => {
  const styles = useStyles();

  if (!active || !label || payload?.length === 0 || payload === null)
    return null;

  return (
    <div className={styles.customizeTooltip}>
      {<div className={styles.tooltipHeader}>Hours: {label}</div>}
      {payload.map(({ name, value, color }) => {
        return (
          <div key={name} style={{ color }} className={styles.payloadContent}>
            {`${name}: ${currencyCode} ${value.toFixed(2)}`}
          </div>
        );
      })}
    </div>
  );
};

const getCurrencyCode = (trends, trends_ly) => {
  const trends_ly_keys = Object.keys(trends_ly);
  let currencyCode = length(trends_ly_keys)
    ? trends_ly[trends_ly_keys[0]].CURRENCY_CODE
    : undefined;

  if (currencyCode === undefined) {
    const trends_keys = Object.keys(trends);
    currencyCode = length(trends_keys)
      ? trends[trends_keys[0]].CURRENCY_CODE
      : undefined;
  }

  return currencyCode ? currencyCode : '';
};

const HourlySalesChart = ({ trends, trends_ly, classes }) => {
  const [chartData, setChartData] = useState(undefined);

  useEffect(() => {
    const tempTrends = trends;
    delete tempTrends['metricId'];
    const tempTrends_ly = trends_ly;
    delete tempTrends_ly['metricId'];
    formattingInChartData(tempTrends, trends_ly);
  }, [trends, trends_ly]);

  const formattingInChartData = (trends, trends_ly) => {
    // const xAxis = Object.keys(trends);
    const xAxis = [
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
    ];
    const data = createSeries(trends, trends_ly, xAxis, 'NET_SELL_OUT_VALUE');
    setChartData(data);
  };

  const currencyCode = getCurrencyCode(trends, trends_ly);

  return (
    <ResponsiveContainer width="95%" height={280} className={classes.container}>
      <ComposedChart data={chartData} margin={{ right: 10 }}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} strokeWidth={1} />
        <XAxis
          dataKey="hours"
          axisLine={{ stroke: '#f5f5f5', strokeWidth: 2 }}
        />
        <Legend
          verticalAlign="bottom"
          align="center"
          height={36}
          iconType="circle"
          wrapperStyle={{ bottom: -15, height: 16, width: '100%' }}
        />
        <Line
          dot={{
            stroke: `${HourlySalesChartDetails.trends.color}`,
            fill: `${HourlySalesChartDetails.trends.color}`,
            strokeWidth: 2,
          }}
          activeDot={{ stroke: `${HourlySalesChartDetails.trends.color}` }}
          strokeWidth={3}
          strokeLinecap="round"
          type="monotone"
          dataKey={HourlySalesChartDetails.trends.datakey}
          stroke={HourlySalesChartDetails.trends.color}
          yAxisId="left"
          legendType="rect"
          name={HourlySalesChartDetails.trends.name}
        />
        <Line
          dot={{
            stroke: `${HourlySalesChartDetails.trends_ly.color}`,
            fill: `${HourlySalesChartDetails.trends_ly.color}`,
            strokeWidth: 2,
          }}
          activeDot={{ stroke: `${HourlySalesChartDetails.trends_ly.color}` }}
          strokeWidth={3}
          strokeOpacity="0.4"
          strokeLinecap="round"
          type="monotone"
          dataKey={HourlySalesChartDetails.trends_ly.datakey}
          stroke={HourlySalesChartDetails.trends_ly.color}
          yAxisId="left"
          legendType="rect"
          name={HourlySalesChartDetails.trends_ly.name}
        />
        <YAxis
          tickLine={false}
          yAxisId="left"
          orientation="left"
          stroke="#FFFFFF"
          label={{
            value: `Hourly Net Sales (in ${currencyCode})`,
            angle: -90,
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 0.7,
              fontSize: 10,
              fontWeight: 100,
              letterSpacing: 1.5,
              transformOrigin: 'left',
              transform: 'rotate(-90deg) translateX(-2.5%) translateY(13%)',
            },
          }}
          axisLine={false}
        />
        <Tooltip
          cursor={false}
          content={
            <CustomizedTooltip
              chartType="hourly-sales-chart"
              currencyCode={currencyCode}
            />
          }
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

HourlySalesChart.propTypes = {
  classes: PropTypes.object.isRequired,
  trends: PropTypes.object.isRequired,
  trends_ly: PropTypes.object.isRequired,
};

export default withStyles(styles)(HourlySalesChart);
