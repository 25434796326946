import React from 'react';
import { Grid, List, ListItem, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Text2 } from '../typography';
import ComparisonIcon from './ComparisonIcon';
import { Panel } from './Panel';
import PerformancePill from './PerformancePill';
import KPiGraphTabs from './Cards/KPiGraphTabs';
import { transformTrends } from '../data/utils/transforms';

const metricPadding = 1;

const styles = theme => ({
  root: {
    padding: theme.spacing(0.75, 0, 0),
    //backgroundColor: '#F5F5F5',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    '& > *': {
      width: '100%',
    },
    // backgroundColor: '#F5F5F5',
    // marginBottom: theme.spacing(1),
    '& .recharts-responsive-container': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  panel: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#F5F5F5',
  },
  metric: {
    padding: theme.spacing(metricPadding, 0.25, metricPadding, 0.25),
    flex: 1,
  },
  comparisonIcon: {
    padding: theme.spacing(1, 0, metricPadding, 0),
    maxWidth: 24,
  },
  pyComparison: {},
  valueComparison: {
    height: 18,
    marginBottom: 4,
  },
  gutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  radio: {
    padding: 0,
  },
  label: {
    textAlign: 'center',
  },
  value: {
    margin: theme.spacing(0, 0, 0.5),
  },
  metricsGrid: {
    backgroundColor: '#F5F5F5',
  },
});

const Metric = ({ classes, metric, ...props }) => {
  const { formattedValue, formattedLLYValue, dpy } = metric ?? {};

  return (
    <Grid
      className={classes.metric}
      item
      container
      direction="column"
      xs={props.xs}
      alignItems="center"
    >
      <Grid item className={classes.value}>
        <Text2 gray>
          {props.useLLY && formattedLLYValue
            ? formattedLLYValue || 'N/A'
            : formattedValue || 'N/A'}
        </Text2>
      </Grid>
      <Grid item>
        {dpy && (
          <PerformancePill
            delta={dpy}
            label={props.useLLY ? 'vs PPY' : 'vs PY'}
          />
        )}
      </Grid>
    </Grid>
  );
};

const ComparedMetricPair = ({ classes, metricPair, useLLY }) => {
  return (
    <Grid container className={classes.metricsGrid}>
      <Metric classes={classes} metric={metricPair.metricA} useLLY={useLLY} />
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        className={classes.comparisonIcon}
      >
        <ComparisonIcon
          comparison={metricPair.comparison}
          className={classes.valueComparison}
          style={{
            visibility:
              typeof metricPair.comparisonPy === 'number' ? 'hidden' : '',
          }}
        />
        <ComparisonIcon
          comparison={metricPair.comparisonPy}
          className={classes.pyComparison}
        />
      </Grid>
      <Metric classes={classes} metric={metricPair.metricB} useLLY={useLLY} />
    </Grid>
  );
};

const CompareList = ({ classes, items, useLLY, useUsdAmount }) => {
  const { formatMessage: f } = useIntl();

  return (
    <List className={classes.root}>
      {items.map(item => {
        // A metric could be available on only one side.
        const panelId = item.metricA?.id || item.metricB?.id;
        const { formattedTrends: metricA } = item?.metricB ?? {};
        const { formattedTrends: metricB } = item?.metricA ?? {};
        const trendItems = transformTrends(metricA, metricB);
        const trendsLength = trendItems ? Object.keys(trendItems).length : 0;
        return (
          <ListItem key={item.id} className={classes.listItem}>
            <Panel
              collapsible={
                item.metricA?.hasTrends && trendsLength > 0 ? true : false
              }
              showTrendsLineIcon={
                item.metricA?.hasTrends && trendsLength > 0 ? true : false
              }
              title={f({ id: `app.${panelId}` })}
              classes={{ topSpacer: classes.panel }}
            >
              {item.metricA?.hasTrends && trendsLength > 0 ? (
                <KPiGraphTabs
                  data={trendItems}
                  dots={true}
                  dataKeyA="value"
                  dataKeyB="value_cmp"
                  strokeA="#35CAEE"
                  strokeB="#8884d8"
                  useLLY={useLLY}
                  format={item.metricA?.format || item.metricB?.format}
                  useUsdAmount={useUsdAmount}
                />
              ) : (
                <></>
              )}
            </Panel>
            <ComparedMetricPair
              classes={classes}
              metricPair={item}
              useLLY={useLLY}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

const idValidator = PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  .isRequired;
const metricValidator = PropTypes.shape({
  id: idValidator,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-foreign-prop-types
  dpy: PerformancePill.propTypes.delta,
});

CompareList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: idValidator,
      metricA: metricValidator,
      metricB: metricValidator,
      comparison: PropTypes.number,
      comparisonPy: PropTypes.number,
    })
  ).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompareList);
