import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';

import Pill from './Pill';
import { Text3 } from '../typography';
import { convertTextInTranslationKey } from '../data/utils/transforms';

const styles = {
  root: {
    minWidth: 99,
    fontSize: '1.2em',
  },
};

const PerformancePill = ({
  classes,
  delta,
  label = 'vs PY',
  showArrow = true,
  ...props
}) => {
  const { formatMessage: f } = useIntl();

  return (
    <Pill className={classes.root} color={delta.sentiment} {...props}>
      <Text3 bold component="span">
        {isFinite(delta.delta)
          ? `${delta.formattedDelta} ${f({
              id: convertTextInTranslationKey(label),
            })}`
          : `--${f({ id: 'app.na' })}--`}
      </Text3>
    </Pill>
  );
};

PerformancePill.propTypes = {
  // eslint-disable-next-line react/forbid-foreign-prop-types
  ...Pill.propTypes,
  delta: PropTypes.shape({
    delta: PropTypes.number,
    formattedDelta: PropTypes.string.isRequired,
    sentiment: PropTypes.oneOf(['good', 'bad', 'neutral']),
  }).isRequired,
  label: PropTypes.string,
};

export default withStyles(styles, { name: 'LriPerformancePill' })(
  PerformancePill
);
