import cx from 'classnames';
import React from 'react';
import { Button, Container, IconButton, withStyles } from '@material-ui/core';
import { Close, CollapseButtonIcon } from '../Icons';
import { Heading4 } from '../../typography';
import IconPlacer from './IconPlacer';
import { alpha } from '@material-ui/core/styles';

const styles = theme => ({
  root: {},
  header: {
    backdropFilter: 'blur(4px)',
    background: alpha(theme.palette.background.paper, 0.65),
    borderTopLeftRadius: theme.shape.borderRadius * 3,
    borderTopRightRadius: theme.shape.borderRadius * 3,
    position: 'relative',
    zIndex: theme.zIndex.tooltip,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    padding: theme.spacing(3, 0, 3, 0),
  },
  content: {
    padding: theme.spacing(0, 0, 1),
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  back: {
    transform: 'rotate(90deg)',
  },
  closeContent: {
    marginRight: theme.spacing(1),
  },
});

const SelectHolder = ({
  classes,
  children,
  closeContent,
  showBack = false,
  title,
  onBack,
  onClose,
}) => {
  const back = (
    <IconButton onClick={onBack}>
      <CollapseButtonIcon className={classes.back} />
    </IconButton>
  );
  const exit = closeContent ? (
    <Button
      className={cx(classes.close, classes.closeContent)}
      onClick={onClose}
    >
      {closeContent}
    </Button>
  ) : (
    <IconButton onClick={onClose}>
      <Close className={classes.close} />
    </IconButton>
  );

  return (
    <>
      <div className={classes.header}>
        <IconPlacer iconLeftSpacing={0} icon={showBack && back}>
          <IconPlacer iconRightSpacing={0} icon={onClose && exit}>
            <Heading4
              className={classes.title}
              align="center"
              component="span"
              gray
            >
              {title}
            </Heading4>
          </IconPlacer>
        </IconPlacer>
      </div>
      <Container className={classes.content}>{children}</Container>
    </>
  );
};

export default withStyles(styles, { name: 'LriSelectHolder' })(SelectHolder);
