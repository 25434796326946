import { parseISO } from 'date-fns';

export const formatDate = new Intl.DateTimeFormat(undefined, {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}).format;

/**
 *
 * @param {number} value
 * @param {Intl.NumberFormatOptions} options
 */
export const formatNumber = (value, options = {}) =>
  new Intl.NumberFormat(undefined, options).format(value);

export const formatInterval = (from, to) =>
  formatDate(from) + ' – ' + formatDate(to);

const abbreviate = (n, options = {}) => {
  let { kMark } = options;
  kMark = kMark || 100000;
  const amt = Math.abs(n);

  // if (amt >= 1000000) { commenting this to show higher values in thousands
  //   return {
  //     numericValue: Math.round(n / 10000) / 100,
  //     abbreviation: 'M',
  //   };
  // } else
  if (amt >= kMark) {
    return {
      numericValue: Math.round(n / 1000),
      abbreviation: 'K',
    };
  }
  if (amt >= 1000) {
    return {
      numericValue: Math.round(n),
      abbreviation: '',
    };
  }
  return {
    numericValue: n,
    abbreviation: '',
  };
};

export const formatAbbreviatedNumber = n => {
  const { numericValue, abbreviation } = abbreviate(n, { kMark: 1000 });
  const formatted = formatNumber(numericValue);

  return `${formatted}${abbreviation}`;
};

export const formatMoney = ({
  currency_code,
  value,
  maximumFractionDigits,
}) => {
  const intl = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency_code,
    maximumFractionDigits,
    minimumFractionDigits: 0,
  });
  let n = Number(value);
  const { numericValue, abbreviation } = abbreviate(n);

  const display = intl.format(numericValue);

  return display.replace(/\.00/, '') + abbreviation;
};

export const formatPercentage = (
  n,
  { maximumFractionDigits = 2, alwaysShowSign = false } = {}
) => {
  const result = new Intl.NumberFormat(undefined, {
    style: 'percent',
    maximumFractionDigits,
  }).format(n === 0 ? 0 : n); // -0 will output "-0%", so pass just 0
  return alwaysShowSign ? `${n < 0 ? '' : '+'}${result}` : result;
};

export const formatPercentagePoints = (n, { alwaysShowSign = false } = {}) => {
  const result =
    formatNumber(n === 0 ? 0 : n * 100, { maximumFractionDigits: 1 }) + ' ppt';
  return alwaysShowSign ? `${n < 0 ? '' : '+'}${result}` : result;
};

export const formatKpi = (
  numericValue,
  { format, currency, maximumFractionDigits }
) => {
  switch (format) {
    case 'money':
      return formatMoney({
        value: numericValue,
        currency_code: currency,
        maximumFractionDigits,
      });
    case 'number':
      return formatNumber(numericValue, { maximumFractionDigits });
    case 'percentage':
      return formatPercentage(numericValue, { maximumFractionDigits });
    case 'percentage-points':
      return formatPercentagePoints(numericValue, { maximumFractionDigits });
    case 'money-per-square-meter':
      return `${formatMoney({
        value: numericValue,
        currency_code: currency,
        maximumFractionDigits,
      })}/m²`;
    default:
      throw new Error(`Unknown format: ${format}`);
  }
};

const intlDateFormatter = new Intl.DateTimeFormat(undefined, {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
});

const intlDateFormatter1 = new Intl.DateTimeFormat('en', {
  day: 'numeric',
  month: 'short',
  hour: '2-digit',
  minute: 'numeric',
});

export const formatTimeStamp = isoString => {
  const date = parseISO(isoString);
  return intlDateFormatter.format(date);
};
export const formatTimeStamp1 = isoString => {
  const date = parseISO(isoString);
  return intlDateFormatter1.format(date);
};
