import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useSecurity } from '../auth';
import { PreferencesManager } from '../data/user-preferences';

export function LanguageProvider(props) {
  const { user } = useSecurity();
  const userId = user?.profile?.sub;
  const pm = new PreferencesManager(userId);
  pm.load();

  const language = props.locale || pm.get('language') || 'en';

  return (
    <IntlProvider
      locale={language}
      key={language}
      messages={props.messages[language]}
    >
      {React.Children.only(props.children)}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
