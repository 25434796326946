const errors = {
  access_denied: {
    title: 'Access denied',
    message: 'app.error.access_denied',
  },
  generic: {
    title: 'Login error',
    message: 'app.error.generic',
  },
  redirect_failed: {
    title: 'Connection error',
    message: 'app.error.redirect_failed',
  },
  temporarily_unavailable: {
    title: 'Temporarily unavailable',
    message: 'app.error.temporarily_unavailable',
  },
};

/**
 * @param {string} errorType
 *   One of: access_denied, generic, redirect_failed or temporarily_unavailable.
 *   Any other value will result in `undefined`.
 * @returns {{ title: string, message: React.ReactNode }|undefined}
 */
export const errorMessage = errorType => errors[errorType];
