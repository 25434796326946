import React from 'react';
import { withStyles, CardContent } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { Text2 } from '../typography';
// import { ErrorPage } from '../components/Errors';
// import LoadingBar from '../components/LoadingBar';
import { CardGrid, Card } from '../components/Cards';

// import useKpis from '../data/hooks/useKpis';
import { KPIList } from '../data/kpi-definition/definition';

const styles = theme => ({
  cardGrid: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
    alignItems: 'stretch',
    '& .MuiCard-root': {
      borderRadius: 2,
      // background: '#FFF',
      padding: '8px 10px',
      // boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      background: 'rgba(0, 0, 0, 0.04)',
      marginTop: 0,
    },
  },
  cardContent: {
    width: '100%',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5) + 2}px`,
    color: '#000',
  },
  title: {
    fontSize: '1.2rem',
    color: '#07507e',
  },
  definition: {
    fontSize: '1.2rem',
  },
});

const KPIDefinition = ({ classes }) => {
  const { formatMessage: f } = useIntl();
  // const { data = [], loading, error, refetch } = useKpis();

  // if (loading) {
  //   return <LoadingBar />;
  // }
  // if (error) {
  //   return (
  //     <ErrorPage
  //       message={f({ id: 'app.could_not_load_for' })}
  //       onClickRetry={refetch}
  //     />
  //   );
  // }
  // const KpiDefinition = data.map(kpiId => {
  //   return {
  //     kpiName: f({ id: `app.${kpiId.metricId}` }),
  //     definition: `${kpiId.description}`,
  //   };
  // });

  const KpiDefinition = KPIList.map(kpiId => {
    return {
      kpiName: f({ id: `app.${kpiId}` }),
      definition: f({ id: `app.kpidefinition.${kpiId}` }),
    };
  });

  const sortKPI = KpiDefinition.sort((a, b) =>
    a.kpiName.localeCompare(b.kpiName)
  );
  return (
    <CardGrid
      justify="flex-start"
      itemXs={12}
      itemSm={6}
      itemMd={6}
      itemLg={3}
      className={classes.cardGrid}
      spacing={1}
    >
      {sortKPI.map(({ kpiName, definition }) => {
        return (
          <Card
            key={'kpi'}
            color="tertiary"
            justify="stretch"
            className={classes.cardCard}
          >
            <CardContent className={classes.cardContent}>
              <Text2
                component="p"
                className={classes.title}
                style={{ fontWeight: 500 }}
              >
                {kpiName}
              </Text2>
              <Text2
                component="span"
                className={classes.definition}
                style={{ opacity: 0.8 }}
              >
                {definition}
              </Text2>
            </CardContent>
          </Card>
        );
      })}
    </CardGrid>
  );
};

export default withStyles(styles, { name: 'LriKPIDefinition' })(KPIDefinition);
