import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, CardContent, CardHeader } from '@material-ui/core';
import { CardGrid, Card } from '../components/Cards';
import TrafficConversionChart from '../components/Charts/recharts/TrafficConversionChart';
import HourlySalesChart from '../components/Charts/recharts/HourlySalesChart';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  cardHeader: {
    '& .MuiTypography-h5': {
      fontSize: '2rem',
    },
  },
  cardContent: {
    width: '100%',
    height: 300,
  },
});

const RealTimeGraphs = ({ classes, trends, trends_ly }) => (
  <div className={classes.container}>
    <CardGrid
      justify="flex-start"
      itemXs={12}
      itemSm={12}
      itemMd={6}
      itemLg={6}
    >
      <Card
        key="traffic-conversion"
        color="tertiary"
        spacing={0.5}
        justify="flex-start"
      >
        <CardHeader
          className={classes.cardHeader}
          title="Hourly Traffic & Conversion"
        />
        <CardContent className={classes.cardContent}>
          <TrafficConversionChart trends={trends} />
        </CardContent>
      </Card>
      <Card
        key="hourly-sales"
        color="tertiary"
        spacing={0.5}
        justify="flex-start"
      >
        <CardHeader
          className={classes.cardHeader}
          title="Hourly Net Sales TY/LY"
        />
        <CardContent className={classes.cardContent}>
          <HourlySalesChart trends={trends} trends_ly={trends_ly} />
        </CardContent>
      </Card>
    </CardGrid>
  </div>
);

RealTimeGraphs.propTypes = {
  classes: PropTypes.object.isRequired,
  trends: PropTypes.object.isRequired,
  trends_ly: PropTypes.object.isRequired,
};
export default withStyles(styles)(RealTimeGraphs);
