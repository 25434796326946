import { renameProp } from '../../util/object';
import {
  collapseToCurrency,
  normalizeFormatAndCurrency,
  formatValues,
  selectDisplayFields,
  attachAndFormatTimeStamp,
} from './transforms';

import { selectCurrencyFromProductsResponse } from './currencySelectors';

const salesId = 'retail_sales_net_value';
const salesWithTaxId = 'retail_sales_net_value_with_tax';
const unitsId = 'sales_units_net';
const inventoryUnitsId = 'ioh_units_eop';

function bringItemsMetricsToMetricsEndpointShape(
  products = {},
  currency,
  includeTaxes
) {
  let r = {};

  Object.keys(products).map(key => {
    let category = {};
    const categoryData = products[key];
    Object.keys(categoryData).map(categoryKey => {
      const endpointData = categoryData[categoryKey]
        .map(x => ({ ...x }))
        .map(renameProp('product', 'id'))
        .map(renameProp('imageUrl', 'imgSrc'))
        .map(p => {
          let sales;
          if (p[salesId]) {
            sales = {
              id: salesId,
              ...p[salesId],
              format: 'money',
            };
          }

          let salesWithTax;
          if (p[salesWithTaxId]) {
            salesWithTax = {
              id: salesWithTaxId,
              ...p[salesWithTaxId],
              format: 'money',
            };
          }

          let units;
          if (p[unitsId]) {
            units = {
              id: unitsId,
              ...p[unitsId],
              format: 'number',
            };
          }

          let inventoryUnits;
          if (p[inventoryUnitsId]) {
            inventoryUnits = {
              id: inventoryUnitsId,
              ...p[inventoryUnitsId],
              format: 'number',
            };
          }
          return { ...p, sales, salesWithTax, units, inventoryUnits };
        });

      return (category[categoryKey] = endpointData?.map(item =>
        transformItemSalesAndUnitMetrics(item, currency, includeTaxes)
      ));
    });
    return (r[key] = category);
  });

  return r;
}
const compose = funcs => objectArg => {
  return funcs.reduce((obj, f) => f(obj), objectArg);
};

function transformItemSalesAndUnitMetrics(p, currency, includeTaxes) {
  const composed = compose([
    // Standardize response
    // attachKpiMeta skipped (response does not offer the metric ids)
    normalizeFormatAndCurrency,
    // attachPrevPeriod skipped (not need for dpys in display)
    // collapseAndAttachWithTaxMetric skipped (response does not include tax metric on the item and also the kpi meta is not attached)
    // App Processing
    x => collapseToCurrency(x, currency),
    // attachDpy skipped (not need for dpys in display)
    x => formatValues(x, { maximumFractionDigits: 0 }),
    selectDisplayFields,
  ]);

  let sales;
  if (includeTaxes) {
    sales = p.salesWithTax && composed(p.salesWithTax);
  } else {
    sales = p.sales && composed(p.sales);
  }
  const units = p.units && composed(p.units);
  const inventoryUnits = p.inventoryUnits && composed(p.inventoryUnits);

  return { ...p, sales, units, inventoryUnits };
}

function processCategory(category, currency, includeTaxes) {
  const menKey = 'mens';
  const womenKey = 'womens';
  const boysKey = 'boys';
  const girlsKey = 'girls';
  return [
    {
      id: 'men',
      genderLabel: 'app.men',
      items: bringItemsMetricsToMetricsEndpointShape(
        category?.[menKey],
        currency,
        includeTaxes
      ),
    },
    {
      id: 'women',
      genderLabel: 'app.women',
      items: bringItemsMetricsToMetricsEndpointShape(
        category?.[womenKey],
        currency,
        includeTaxes
      ),
    },
    {
      id: 'boys',
      genderLabel: 'app.boys',
      items: bringItemsMetricsToMetricsEndpointShape(
        category?.[boysKey],
        currency,
        includeTaxes
      ),
    },
    {
      id: 'girls',
      genderLabel: 'app.girls',
      items: bringItemsMetricsToMetricsEndpointShape(
        category?.[girlsKey],
        currency,
        includeTaxes
      ),
    },
  ];
}

export function transformProducts_v2(
  productsResponse,
  useUsdAmount,
  includeTaxes
) {
  const currency = useUsdAmount
    ? 'USD'
    : selectCurrencyFromProductsResponse(
        productsResponse['total_sales']['PC9']
      );

  let result = {};
  Object.keys(productsResponse).forEach(salesType => {
    let product = {};
    Object.keys(productsResponse[salesType]).forEach(productType => {
      product[productType] = {
        best_sellers: processCategory(
          productsResponse[salesType][productType].best_sellers,
          currency,
          includeTaxes
        ),
        worst_sellers: processCategory(
          productsResponse[salesType][productType].worst_sellers,
          currency,
          includeTaxes
        ),
        blanks: processCategory(
          productsResponse[salesType][productType].blank_sellers,
          currency,
          includeTaxes
        ),
      };
    });
    result[salesType] = product;
  });

  // TODO: (actual API) It's advised that the `asOf` be at the root of the response
  attachAndFormatTimeStamp(result, productsResponse.asOf);

  return result;
}
