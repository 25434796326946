import React, { useState } from 'react';
import { SelectDrawer } from '../components/Select';
import { Avatar, Card, CardMedia, makeStyles } from '@material-ui/core';
import { Image as ImageIcon } from '@material-ui/icons';
import { Heading3 } from '../typography';

const useStyles = makeStyles(
  theme => ({
    root: { width: '100%' },
    imageContainer: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px 0`,
    },

    avatar: {
      borderRadius: 4,
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: '#EFEFEF',
    },
    avatarBroken: {
      borderColor: 'transparent',
    },
    avatarBrokenIcon: {
      color: theme.palette.action.active,
    },
    media: {
      height: '460px',
      [theme.breakpoints.up('md')]: {
        backgroundSize: 'contain',
      },
    },

    imageContent: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 0.5),
      },
    },
    name: {
      margin: theme.spacing(0.5, 0),
      lineHeight: '20px',
      fontWeight: 'bold !important',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
      },
    },
    productsList: { padding: theme.spacing(0.5, 0) },
  }),
  { name: 'LriProductImageView' }
);

const PerformanceItemView = ({ item }) => {
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();

  const viewItem = () => {
    setDrawer(true);
  };

  const onClose = () => {};

  return (
    <>
      <SelectDrawer
        open={drawer}
        title={item.id}
        onClose={() => {
          onClose();
          setDrawer(false);
        }}
      >
        <div className={classes.root}>
          <Item items={item} styles={classes} />
        </div>
      </SelectDrawer>
      <Avatar
        className={classes.avatar}
        classes={{ colorDefault: classes.avatarBroken }}
        src={item.imgSrc}
        onClick={viewItem}
      >
        <ImageIcon className={classes.avatarBrokenIcon} />
      </Avatar>
    </>
  );
};

const Item = ({ items, styles }) => {
  const imageSrc = items.imgSrc.replace('$thumb_mobile$', '');
  return (
    <Card className={styles.imageContainer}>
      <CardMedia className={styles.media} image={imageSrc} />
      <div className={styles.imageContent}>
        <Heading3 caps className={styles.name}>
          {items.name}
        </Heading3>
      </div>
    </Card>
  );
};

export default PerformanceItemView;
